import React from 'react'
import '../Skins.css'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';


function Speed() {
  useEffect(() => {
    document.title = "be! skinhub";
  }, []);
  return (
    <div>
      <div className='skins-bg'>   
      
       <h1 className='skins-title'> 
       <a className='skins-link' href='https://osu.ppy.sh/users/12067932'>
        speed <img className='avatar' src='http://s.ppy.sh/a/12067932?815'></img>
        </a>
        </h1>
        <div className='skins-container'>
            <div className='skins'>
                <div className='bdt'>
                    <a href='https://www.mediafire.com/file/sad3qs9595z73q9/bdt.osk/file'>
                    <img className='bdt-gif'src='https://be.s-ul.eu/NlfdMgAd'></img>
                    <p className='bdt-title'>bdt</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/cl0x5hz3ggsxpho/bdt-sb.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/3IGaPqJa'></img>
                    <p className='elohere-title'>bdt-sb</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/ii31lfc6yfibpm0/bcolorful.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/dIwbjVzY'></img>
                    <p className='elohere-title'>bcolorful</p> 
                    </a>
                </div>
            </div>
        </div>
       </div> 
    </div>
  )
}

export default Speed
