import '../App.css';
import React from 'react';
import { useState } from "react";
import { LastFmData } from '../Lastfm';
import flag from '../flag.png';
import art from '../art.png';
import Modal from './Modal';
import { Link } from 'react-router-dom';
import TwitchStreamerStatus from '../Twitch';


function MainPage() {

  const [openModal, setOpenModal] = useState(false);


  return (
    <div className="wrapper">
      {openModal && <div className="modal-background"></div>}
      <div className="art">
      <img className='art' href='https://x.com/sdns53/status/1694634792762114120?s=20' alt='https://x.com/sdns53/status/1694634792762114120?s=20' src={art}></img>
        </div>
      <div className="header">
        <h1 className='welcome'> welcome!</h1>
        <a className='oi'>information systems student | 20 | <img src={flag}></img></a>
      <div  className='info'>
        <a className='osu-profile' href='https://old.ppy.sh/u/speed'>osu!</a>
        <br></br>
        <a className='steam-profile' href='https://steamcommunity.com/id/hzd-/'>steam</a>
        <br></br>
        <a className='github-profile' href='https://github.com/b9rnardo'>github</a>
        <br></br>
        <a className='twitch-profile' href='https://www.twitch.tv/b9rnardo'>twitch</a> 
        <br></br>
        <a className='lastfm-profile' href='https://www.last.fm/user/beernardoo'>last.fm</a>
        <br></br>
        <a className='rym-profile' href='https://rateyourmusic.com/~b9rnardo'>rym</a>
        <br></br>
        <a className='modal-setup' href="#" onClick={() => {setOpenModal(true)}}>setup</a>
        <br></br>
        {openModal && (
        <div className="modal-content"> 
          <Modal closeModal={setOpenModal}/>}
        </div>
        )}
        <Link to="/skins "><a className='osu-skins'>osu! skinhub</a></Link>
      </div>

    <div className='steam-group'>
      <a>drawing by: <a href='https://twitter.com/sdns53'>@sdns53 on twitter</a></a> <br></br>
      <a href='https://steamcommunity.com/groups/shibahub'>shiba! steam group</a>
      <hr></hr>
    </div>
    
    <div className='lastscrobble'>
      <LastFmData
      userName={'beernardoo'}
      apiKey={'ab17dcf8fe188fa05d986ea49c433c2a'}
      />
    </div>
    </div>
      </div>
  );
}

export default MainPage;
