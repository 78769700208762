import React, { useState, useEffect } from 'react';

export const LastFmData = ({ userName, apiKey }) => {
    const [lfmData, updateLfmData] = useState({});
    
    useEffect(() => {
        fetch(`https://ws.audioscrobbler.com/2.0/?method=user.getRecentTracks&user=${userName}&api_key=${apiKey}&limit=1&nowplaying=true&format=json`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('error');
            })
            .then(data => updateLfmData(data))
            .catch(() =>
                updateLfmData({ error: 'Whoops! Something went wrong with Last.fm' })
            );
    }, []);
    
    const buildLastFmData = () => {
        const  { error } = lfmData;
        const track = lfmData?.recenttracks?.track;
    
        if (error) {
            return <p>{error}</p>;
        }
    
        if (!track) {
            return <p>loading</p>;
        }
    
        const [
            { name: songName, artist: { '#text': artistName } = {}, image: songImage } = {}
        ] = track;
    

        return (
            <>
                <a>my lastest scrobble:<br></br>{songName} by {artistName}</a> <img src={songImage[1]['#text']} alt={`${songName} by ${artistName}`} />
            </>
        );
    };

    return buildLastFmData();
};

//took from https://medium.com/@samkitson/fetching-and-displaying-scrobbled-data-with-react-and-the-last-fm-api-28d048386da8
