import React from 'react'
import { Skins, MainPage, Delta, Speed, Anton } from './components'
import {Routes, Route} from 'react-router-dom';

function App() {
  return (
    <div>
      <Routes>
        <Route path={'/'} element={ <MainPage /> }/>
        <Route path={'/skins'} element={ <Skins /> }/>
        <Route path={'/delta'} element={ <Delta /> }/>
        <Route path={'/speed'} element={ <Speed /> }/>
        <Route path={'/anton'} element={ <Anton /> }/>
      </Routes>
    </div>
  )
}

export default App