import React from 'react';
import { useEffect } from 'react';
import './Skins.css';
import Delta from './players/delta';
import Speed from './players/speed';
import Anton from './players/anton';
import { Link } from 'react-router-dom';

function Skins() {
    useEffect(() => {
        document.title = "skinhub!";
      }, []);
    return (
        <div>
            <Link to="/ "><button className='back-button'> ← </button></Link>
            <Speed />
            <Delta />
            <Anton />
        </div>
        );
    }
    export default Skins;